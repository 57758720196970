import React from "react"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import img from "../img/about-right-1.jpg"
import ButtonLink from "../components/ButtonLink"
import Footer from "../components/Footer"
import { Main, MainContainer, Grid } from "../components/content/About/styled"

const About = () => {
  return (
    <Layout>
      <Helmet
        name="About Us"
        description="Mechanical Air Conditioning is St. Lucie, Martin and Palm Beach County’s trusted choice for HVAC sales and service. Family owned & operated for over 40 years, 100% satisfaction, guaranteed!"
        slug="about"
      />
      <Header />
      <PageHdr title="About Us" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <h2>
                Family owned & operated for over 40 years in Palm Beach County
              </h2>
              <p>
                Mechanical Air Conditioning is a multi-generational company
                dedicated to providing a customer-first experience. When you
                live in West Palm Beach, FL or any of the surrounding
                communities, you know how sporadic our weather can be. We suffer
                from storms, hurricanes, excessive heat and humidity, and even
                the occasional chilly winter. Our team understands this and is
                committed to keeping you comfortable and safe with the right
                HVAC and indoor air quality equipment, service, and advice.
              </p>

              <p>
                We work hard to earn your trust as a leading HVAC contractor,
                and provide full-service HVAC care. This includes repair,
                installation, and maintenance services to ensure your systems
                stay in great shape. We install and repair HVAC systems from any
                manufacturer and provide financing options (subject to approved
                credit) to help you invest in a new system, as well as
                comprehensive maintenance tune-ups and 24/7 emergency repairs.
                We want to provide value to you, and we look forward to showing
                you how.
              </p>

              <h3>Committed to Quality HVAC Products and Affordable Prices</h3>

              <Grid>
                <div>
                  <p>
                    Mechanical Air Conditioning owner, Norm Sayre, has long been
                    known for his commitment to hard work and superior work
                    ethic. This shows in everything we do as a company—we’re
                    different from our competitors because we focus first on
                    offering our customers reputable, reliable, and professional
                    air conditioning service and indoor air quality service.
                  </p>

                  <p>
                    Our team believes in ensuring you are 100% satisfied with
                    not only the quality of the products we install, but the
                    service we provide. Our service technicians are NATE
                    certified and we work with leading brands to bring you the
                    best in HVAC and indoor air performance.
                  </p>

                  <p>
                    We thank you for making Mechanical Air Conditioning your
                    preferred HVAC contractor and we look forward to continuing
                    to provide you with exceptional service.
                  </p>

                  <ButtonLink to="/contact" name="Contact Us Today" />
                </div>
                <div>
                  <img src={img} alt="Tech oustide service van" />
                </div>
              </Grid>
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default About
