import styled from "styled-components"
import {
  Main as BaseMain,
  MainContainer as BaseMainContainer,
} from "../../../elements/Layout/styled"

export const Main = styled(BaseMain)``

export const MainContainer = styled(BaseMainContainer)`
  & h3 {
    margin-top: 25px;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 25px;
  align-items: center;
  justify-items: center;

  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
    align-items: start;
  }

  & > div:first-child {
    border: 1px solid #ddd;
    padding: 17.5px;
  }

  & > div:last-child {
    padding: 15px;

    & > img {
      border-radius: 12.5%;
    }
  }
`
